@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.intro {
  $block-name: &;

  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  z-index: 0;

  @include mq($to: lg) {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  &__cell-left {
  }

  &__cell-right {
    text-align: right;

    @include mq($to: md) {
      padding-top: 30px;
    }
  }

  &__cell-left,
  &__cell-right {
    width: calc(50% - 20px);

    @include mq($to: md) {
      width: auto;
      text-align: center;
    }
  }

  &__cell-button {
    @include reset-text-indents;
  }

  &__cell-text {
    margin-bottom: 60px;
    max-width: 540px;

    @include mq($to: lg) {
      margin-bottom: 40px;
    }

    @include mq($to: md) {
      max-width: none;
      margin-bottom: 20px;
    }
  }

  &__experience {
    &:not(:last-child) {
      margin-bottom: 50px;

      @include mq($to: lg) {
        margin-bottom: 40px;
      }

      @include mq($to: md) {
        margin-bottom: 30px;
      }
    }
  }

  &__experience-text {
    .text {
      @include mq($to: md) {
        font-size: 16px;
      }
    }
  }

  &__experience-title {
    margin-bottom: 3px;

    .title {
      @include mq($to: md) {
        font-size: 32px;
      }
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    @include mq($to: md) {
      display: block;
    }
  }

  &__inner {
  }

  &__title {
    @include position(absolute, 0 0 0 0);

    color: $black-100;
    text-align: center;
    font-family: $font-family--headings-narrow;
    font-weight: 600;
    letter-spacing: -4.32px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 110%;
    vertical-align: middle;
    padding-bottom: 10px;

    // this

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include fp(font-size, 120, 161, map-get($breakpoints, xxl), map-get($breakpoints, xxxl));

    @include mq($to: xxl) {
      @include fp(font-size, 90, 120, map-get($breakpoints, lg), map-get($breakpoints, xxl));
    }

    @include mq($to: 1380px) {
      @include fp(font-size, 90, 126, map-get($breakpoints, lg), map-get($breakpoints, xxl));
    }

    @include mq($to: lg) {
      @include fp(font-size, 43, 90, map-get($breakpoints, sm), map-get($breakpoints, lg));

      letter-spacing: -2.16px;
    }

    @include mq($to: sm) {
      @include fp(font-size, 30, 41, 375, 500);

      letter-spacing: -0.96px;
    }
  }

  &__title-development {
    color: #3b1faa;
    font-family: $font-family--headings;
    font-weight: 700;
  }

  &__title-ecom {
    color: #3b1faa;
  }

  &__title-engineers {
    @include size(px-to-em(170, 164px));

    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -0.39em;
    width: 1.1em;
    height: 1.1em;
    border: 2px solid $light-gray-100;
    padding: 0;
    overflow: visible;
    transition: all $transition-time--v-slow;
    text-align: center;
    text-decoration: none;
    margin-bottom: -0.39em;
    border-radius: $border-radius-rounded;
    letter-spacing: normal;
    text-transform: initial;
    margin-left: 10px;
    margin-right: 10px;
    background-color: transparent;
    text-overflow: clip;
    -webkit-line-clamp: none;

    @include mq($to: lg) {
      display: none;
    }

    &::after {
      @include size(px-to-em(180, 164px));

      content: '';
      display: inline-flex;
      position: absolute;
      z-index: -1;
      border-radius: $border-radius-rounded;
      transition:
        background-color $transition-time--slow,
        width $transition-time--slow,
        height $transition-time--slow;
      background-color: transparent;
    }

    &:hover {
      color: $black-400;
      background-color: $white-400;
      border-color: $white-400;

      #{$block-name} {
        &__title-engineers-bottom,
        &__title-engineers-top {
          color: $black-400;
        }
      }

      &::after {
        background-color: $white-300;
        @include size(px-to-em(200, 164px));
      }
    }

    &:active {
      border-color: $color-secondary;
    }
  }

  &__title-engineers-bottom {
    display: inline-flex;
    flex-direction: column;
    transition: color $transition-time;
    color: $black-100;
    font-family: $font-family;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    @include mq($to: 1450px) {
      font-size: 10px;
    }

    @include mq($to: 1250px) {
      font-size: 8px;
    }
  }

  &__title-engineers-top {
    color: $dark-gray-400;
    font-family: $font-family--headings;
    font-size: px-to-em(28, 164px);
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.56px;
    display: inline-block;
    transition: color $transition-time;
  }

  &__title-foodtech {
    color: #57c235;
    font-family: $font-family--headings;
    font-weight: 700;
  }

  &__title-team {
  }

  &__title-we-are {
  }

  &__top {
    position: relative;
    padding-bottom: 21%;
    margin-bottom: 50px;

    @include mq($to: lg) {
      margin-bottom: 30px;
    }

    @include mq($to: md) {
      margin-bottom: 20px;
    }
  }
}
