@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.projects {
  $block-name: &;

  padding-top: 100px;
  padding-bottom: 100px;

  @include mq($to: xl) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include mq($to: md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__body {
    @include mq($to: lg) {
      margin-bottom: -20px;
    }
  }

  &__inner {
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 30px;

    @include mq($to: lg) {
      display: block;
    }

    &:not(:last-child) {
      margin-bottom: 30px;

      @include mq($to: lg) {
        margin-bottom: 0;
      }
    }
  }

  &__cell {
    width: calc(36.6% - 15px);

    @include mq($to: lg) {
      width: auto;
      margin-bottom: 20px;
    }

    &--large {
      width: calc(63.4% - 15px);

      @include mq($to: lg) {
        width: auto;
      }
    }
  }

  &__title {
    color: $purple-500;
    text-align: center;
    font-family: $font-family--headings;
    font-size: 140px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -4.2px;
    margin-top: 0;
    margin-bottom: 60px;

    @include mq($to: xxl) {
      font-size: 120px;
    }

    @include mq($to: lg) {
      font-size: 64px;
      margin-bottom: 40px;
    }

    span {
      color: $light-gray-200;
    }
  }

  &__title-wrap {
  }
}

.card-project {
  $block-name: &;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 0;
  aspect-ratio: 1.32/1;
  border-radius: 20px;
  text-decoration: none;
  overflow: hidden;

  @include mq($to: lg) {
    aspect-ratio: 794/531;
  }

  @include mq($to: md) {
    aspect-ratio: 335/531;
  }

  &:hover {
    #{$block-name} {
      &__image {
        transform: scale(1.05);
      }

      &__pseudo-button {
        transform: rotate(45deg);
      }
    }
  }

  &__box {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    width: 100%;
    max-width: 100%;
    padding: 30px;

    @include mq($to: md) {
      padding: 20px;
    }
  }

  &__description {
  }

  &__image {
    @include position(absolute, 0 0 0 0);

    z-index: -1;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    transform: scale(1);
    transition: transform $transition-time--slow;
    object-fit: cover;
    will-change: transform;
  }

  &__pseudo-button {
    @include size(60px);

    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid currentColor;
    border-radius: $border-radius-rounded;
    transform: rotate(0);
    transition: transform $transition-time--slow;
    will-change: transform;

    @include mq($to: md) {
      @include size(51px);
    }
  }

  &__tag {
    display: inline-flex;
    position: relative;
    margin: 18px;
    padding: 16px 24px;
    padding-left: 32px;
    border-radius: 43px;
    background: $white-200;
    box-shadow: 0 39px 85px -14px rgb(35 34 94 / 18%);
    color: $black-400;
    font-family: $font-family--headings;
    font-size: $font-size-xs;
    font-weight: $font-weight-l;
    line-height: $line-height-xs;

    &::before {
      @include size(6px);

      content: '';
      display: block;
      position: absolute;
      top: 50%;
      background-color: $black-400;
      border-radius: 50%;
      margin-top: -3px;
      left: 18px;
    }
  }

  &__text-box-left {
    flex: 1 1 auto;
  }

  &__text-box-right {
    flex: 0 0 auto;
    margin-left: 30px;

    @include mq($to: md) {
      position: absolute;
      top: 18px;
      right: 18px;
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &--type-fh {
    background-color: #e8ebf1;

    #{$block-name} {
      &__box {
        color: $black-400;
      }

      &__description {
        color: $black-100;
      }

      &__pseudo-button {
        border-color: $light-gray-200;
      }
    }
  }

  &--type-bushe {
    background-color: #fcc361;
    aspect-ratio: 0.754/1;

    @include mq($to: lg) {
      aspect-ratio: 794/531;
    }

    @include mq($to: md) {
      aspect-ratio: 335/531;
    }

    #{$block-name} {
      &__box {
        color: $white-100;
      }
    }
  }

  &--type-yapoki {
    background-color: #fff678;
    aspect-ratio: 0.755/1;

    @include mq($to: lg) {
      aspect-ratio: 794/531;
    }

    @include mq($to: md) {
      aspect-ratio: 335/531;
    }

    #{$block-name} {
      &__box {
        color: $black-400;
      }

      &__description {
        color: $black-100;
      }

      &__pseudo-button {
        border-color: $dark-gray-400;
      }
    }
  }

  &--type-beerpoint {
    background-color: #edd68f;

    #{$block-name} {
      &__box {
        color: $black-400;
      }

      &__description {
        color: $black-100;
      }

      &__pseudo-button {
        border-color: $dark-gray-400;
      }
    }
  }
}
