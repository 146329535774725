@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.statistics {
  $block-name: &;

  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  z-index: 0;

  @include mq($to: lg) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__bubbles {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__inner {
  }

  &__title-wrap {
    position: relative;
    margin-bottom: 40px;
    text-align: center;

    @include mq($to: sm) {
      margin-bottom: 30px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $white-400;
      margin-top: -1px;
    }
  }

  &__title {
    display: inline-block;
    position: relative;
    z-index: 1;
    color: $light-gray-200;
    text-align: center;
    font-family: $font-family--headings;
    font-size: 96px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -2.88px;
    margin-top: 0;
    margin-bottom: 0;
    background: $white-200;
    padding-left: 34px;
    padding-right: 34px;

    @include mq($to: lg) {
      font-size: 64px;
    }

    @include mq($to: md) {
      font-size: 44px;
    }

    span {
      color: $purple-500;
    }
  }

  &__bubble {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    margin: 12px;
    border: 1px solid $white-400;
    font-size: 20px;
    padding: px-to-em(15, 20px);
    overflow: hidden;
    text-decoration: none;
    border-radius: $border-radius-rounded;
    cursor: default;

    @include mq($to: xxl) {
      font-size: 17px;
    }

    @include mq($to: xl) {
      font-size: 14px;
    }

    @include mq($to: lg) {
      font-size: 11px;
      margin: 2px;
    }

    @include mq($to: sm) {
      font-size: 12px;
      margin: 2px;
    }

    &::after {
      @include position(absolute, 0 0 0 0);

      content: '';
      display: block;
      opacity: 0;
      transition: opacity $transition-time--slow;
    }

    &:hover {
      &::after {
        opacity: 1;

        @include mq($to: lg) {
          opacity: 0 !important;
        }
      }

      #{$block-name} {
        &__bubble-title {
          color: $white-100;
          transform: translateY(px-to-em(-25, 48px));
        }

        &__bubble-description {
          color: $white-100;
          opacity: 1;
          transform: translateY(px-to-em(25, 20px));

          // transition-delay: 0.2s;
        }
      }
    }

    &--first {
      @include size(px-to-em(280, 20px));

      &::after {
        background: $grad-green-horizontal;
      }

      #{$block-name} {
        &__bubble-title {
          font-size: px-to-em(48, 20px);
          font-weight: 500;
          line-height: 120%;
          letter-spacing: -1.44px;

          @include mq($to: lg) {
            transform: translateY(px-to-em(-25, 48px)) !important;
          }
        }

        &__bubble-description {
          @include mq($to: lg) {
            transform: translateY(20px) !important;
          }
        }
      }
    }

    &--second {
      @include size(px-to-em(380, 20px));

      padding: px-to-em(40, 20px);

      @include mq($to: sm) {
        @include size(px-to-em(280, 20px));
      }

      &::after {
        background: $grad-purple-vertical-inv;
      }

      &:hover {
        #{$block-name} {
          &__bubble-title {
            transform: translateY(px-to-em(-45, 64px));
          }

          &__bubble-description {
            transform: translateY(px-to-em(40, 24px));

            @include mq($to: sm) {
            }
          }
        }
      }

      #{$block-name} {
        &__bubble-title {
          font-weight: 500;
          line-height: 120%;
          font-size: px-to-em(64, 20px);
          letter-spacing: -1.92px;

          @include mq($to: lg) {
            transform: translateY(px-to-em(-45, 64px)) !important;
          }

          @include mq($to: sm) {
            font-size: 36px;
          }
        }

        &__bubble-description {
          font-size: px-to-em(24, 20px);
          transform: translateY(px-to-em(75, 20px));

          @include mq($to: lg) {
            transform: translateY(px-to-em(40, 26px)) !important;
          }
        }
      }
    }

    &--third {
      @include size(px-to-em(280, 20px));

      @include mq($to: sm) {
        @include size(px-to-em(280, 20px));
      }

      &::after {
        background: $grad-green-horizontal;
      }

      &:hover {
        #{$block-name} {
          &__bubble-title {
            transform: translateY(px-to-em(-20, 48px));
          }
        }
      }

      #{$block-name} {
        &__bubble-title {
          font-size: px-to-em(48, 20px);
          font-weight: 500;
          line-height: 120%;
          letter-spacing: -1.44px;

          @include mq($to: lg) {
            transform: translateY(px-to-em(-20, 48px)) !important;
          }
        }
      }
    }

    &--fourth {
      @include size(px-to-em(221, 20px));

      @include mq($to: sm) {
        @include size(px-to-em(280, 20px));
      }

      &::after {
        background: $black-100;
      }

      &:hover {
        #{$block-name} {
          &__bubble-title {
            transform: translateY(px-to-em(-20, 40px));
          }

          &__bubble-description {
            transform: translateY(px-to-em(20, 18px));
          }
        }
      }

      #{$block-name} {
        &__bubble-title {
          text-align: center;
          font-size: px-to-em(40, 20px);
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: -1.2px;

          @include mq($to: lg) {
            transform: translateY(px-to-em(-20, 40px)) !important;
          }
        }

        &__bubble-description {
          font-size: px-to-em(18, 20px);
          margin-bottom: -1.5em;

          @include mq($to: lg) {
            transform: translateY(15px) !important;
          }
        }
      }
    }
  }

  &__bubble-title {
    position: relative;
    z-index: 1;
    color: $purple-500;
    text-align: center;
    font-family: $font-family--headings;
    font-size: px-to-em(48, 20px);
    font-style: normal;
    font-weight: $font-weight-m;
    line-height: 120%;
    letter-spacing: -1.44px;
    margin-top: 0;
    margin-bottom: 0;
    transform: translateY(0);
    transition:
      transform $transition-time--slow,
      color $transition-time;
    will-change: transform;

    @include mq($to: lg) {
      color: $purple-500 !important;
      transform: translateY(px-to-em(-25, 48px)) !important;
    }
  }

  &__bubble-description {
    position: absolute;
    z-index: 1;
    font-family: $font-family;
    font-size: px-to-em(20, 20px);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-top: px-to-em(10, 20px);
    margin-bottom: 0;
    text-align: center;
    padding: px-to-em(20, 20px);
    opacity: 0;
    transform: translateY(px-to-em(45, 20px));
    color: $white-100;
    transition:
      opacity $transition-time--slow,
      transform $transition-time--slow;
    will-change: transform;

    @include mq($to: lg) {
      opacity: 1 !important;
      color: $black-400 !important;
      transform: translateY(px-to-em(25, 20px)) !important;
    }

    @include mq($to: sm) {
      line-height: 140%;
    }
  }
}
